export const clientInfo = {
  brandName: 'TAOKAEBET',
  announcement: true,
  ios: {
    poster: 'https://download.36be289f.com/iosStep.gif',
    step: 'https://download.36be289f.com/iosStep.mp4',
    url: 'https://ici4b.com/cbrq',
  },
  android: {
    url: 'https://www.download-tkb.site/Taokaebet_release.apk',
  },
};
