import React from 'react';
import {useTranslation} from 'react-i18next';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {clientInfo} from './configs';
import MainPage from './MainPage';

export default function App() {
  const {t} = useTranslation();

  return (
    <Router>
      <div id="dialog" className="news-container">
        <div
          className="news-btn"
          onClick={() => {
            document.getElementById('dialog').style.height = '0%';
          }}>
          &times;
        </div>
        <div className="news-style">
          <div className="news-title" style={{marginTop: 50}}>
            {clientInfo.brandName}
          </div>
          <div className="news-title">{t('news.title')}</div>
          <div className="news-desc">{t('news.content')}</div>
        </div>
      </div>

      <Switch>
        <Route exact path="/">
          <MainPage />
        </Route>
      </Switch>
    </Router>
  );
}
