import React, {useEffect, useState} from 'react';
import '../src/App.css';
import {message, Modal} from 'antd';
import {useTranslation} from 'react-i18next';
import logo from '../src/Assets/logo.png';
import annoucement_icon from '../src/Assets/annoucement_icon.png';
import downloadStep from '../src/Assets/downloadStep.png';
import {QRCode} from 'react-qrcode-logo';
import {clientInfo} from './configs';

export default function MainPage() {
  const {t, i18n} = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const [copyState, setCopyState] = useState(1);

  //#region ===> mobile
  const copy = t('copy.copy');
  const copied = t('copy.copied');
  const failed = t('copy.failed');
  const [visible, setVisible] = useState(false);
  const [iosStep, setIosStep] = useState(false);
  let refcode = '';

  var isiOS =
    navigator.platform === 'MacIntel' || navigator.platform === 'iPhone';

  var isSafari =
    navigator.vendor.match(/apple/i) &&
    !navigator.userAgent.match(/crios/i) &&
    !navigator.userAgent.match(/fxios/i) &&
    !navigator.userAgent.match(/Opera|OPT\//);

  var isLine = navigator.userAgent.indexOf('Line') === -1;

  var isDesktop = window.navigator.maxTouchPoints || 'ontouchstart' in document;
  //#endregion

  useEffect(() => {
    if (refcode === '') {
      alert(
        `รหัสเชิญไม่ถูกต้องโปรดติดต่อผู้อ้างอิงของคุณ\nInvitation code invalid, please contact your referer`,
      );
      return;
    }
  }, [refcode]);

  const androidLink = clientInfo.android.url;
  const search = window.location.search;
  const params = search.substring(1).split('&');

  params.forEach((item, index) => {
    if (item.split('=')[0] === 'rc') {
      refcode = item.split('=')[1];
      return;
    }
  });

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopyState(2);
    } catch (err) {
      alert(err);
      setCopyState(3);
    }
  };

  return (
    <div className="container">
      <div id="overlay" className="overlay">
        <img
          src={downloadStep}
          alt={'downloadStep'}
          className="download-step-img"
        />
      </div>
      <div className="content-container">
        {isiOS ? (
          <>
            <Modal
              bodyStyle={{overflowY: 'scroll'}}
              className={'modal-content'}
              getContainer={false}
              closable={false}
              title={t('modalWarning.title')}
              style={{
                top: 0,
              }}
              visible={visible}
              cancelButtonProps={{style: {display: 'none'}}}
              okButtonProps={{style: {background: 'transparent'}}}
              onOk={() => {
                setVisible(true);
                copyToClipBoard(window.location.href);
              }}
              okText={
                copyState === 1 ? copy : copyState === 2 ? copied : failed
              }>
              <center className="ios-step-container">
                <p className="ios-step-txt">{t('modalWarning.tips1')}</p>
                <p className="ios-step-txt">{t('modalWarning.tips2')}</p>
                <div style={{marginTop: 30}} className="installBtn iosBtn">
                  <span className="ios-step-txt">{t('button.install')}</span>
                </div>
              </center>
            </Modal>
            <Modal
              bodyStyle={{overflowY: 'scroll'}}
              className={'modal-content'}
              closable={false}
              visible={iosStep}
              cancelButtonProps={{style: {display: 'none'}}}
              okButtonProps={{style: {display: 'none'}}}
              footer={null}
              onOk={() => {
                setVisible(true);
                copyToClipBoard(window.location.href);
              }}
              okText={'Install'}>
              <center>
                <video
                  width="300"
                  height="240"
                  controls
                  poster={clientInfo.ios.poster}>
                  <source src={clientInfo.ios.step} />
                </video>
                <div className="ios-step-container">
                  <div className="ios-tips">{t('modalInfo.tips1')}</div>
                  <div className="ios-step-txt">{t('modalInfo.tips2')}</div>
                  <div className="ios-step2-txt">{t('modalInfo.tips3')}</div>
                  <div className="ios-step2-txt">{t('modalInfo.tips4')}</div>
                  <div className="ios-step2-txt">{t('modalInfo.tips5')}</div>
                  <div className="ios-step2-txt">{t('modalInfo.tips6')}</div>
                </div>
                <a
                  style={{marginTop: 30}}
                  className="install-btn ios-btn"
                  href={clientInfo.ios.url}>
                  <span className="ios-txt-btn">{t('button.install')}</span>
                </a>

                <button
                  style={{marginTop: 30}}
                  onClick={() => setIosStep(false)}
                  className="install-btn cancel-btn">
                  <span className="cancel-txt-btn">{t('button.cancel')}</span>
                </button>
              </center>
            </Modal>
          </>
        ) : null}
        <div className="qr-container">
          <QRCode
            value={window.location.href}
            size={160}
            quietZone={5}
            logoImage={logo}
            logoWidth={50}
            logoHeight={50}
          />
        </div>
        <div className="ref-container">
          <div className="ref-label-txt">{t('label.refCode')}</div>
          <div className="ref-style ">
            {refcode ? (
              <>
                <div className="ref-txt">{refcode}</div>
                <button
                  className="ref-btn"
                  onClick={() => {
                    copyToClipBoard(refcode);
                  }}>
                  {t(
                    'copy.' +
                      (copyState === 1
                        ? 'copy'
                        : copyState === 2
                        ? 'copied'
                        : 'failed'),
                  )}
                </button>
              </>
            ) : (
              <div className="ref-empty">-</div>
            )}
          </div>
        </div>
        {isiOS ? (
          <div style={{marginBottom: 30}}>
            {!isDesktop ? (
              <div style={{marginBottom: 30}}>
                <a
                  href={androidLink}
                  onClick={() => {
                    if (refcode) {
                      copyToClipBoard(refcode);
                      message.success(`${refcode} ${t('dialog.refCode')}`, 6);
                      return;
                    }
                  }}>
                  <span className="install-btn ios-btn">
                    {t('button.getApp')}
                  </span>
                </a>
              </div>
            ) : (
              <button
                className="install-btn ios-btn"
                onClick={() => {
                  if (!isSafari) {
                    document.getElementById('overlay').style.display = 'block';
                  } else if (!isLine) {
                    window.location.href =
                      window.location.href + '&openExternalBrowser=1';
                  } else if (refcode) {
                    copyToClipBoard(refcode);
                    message.success(`${refcode} ${t('dialog.refCode')}`, 6);
                    setIosStep(true);
                    return;
                  } else {
                    setIosStep(true);
                  }
                }}>
                {t('button.getApp')}
              </button>
            )}
          </div>
        ) : (
          <div style={{marginBottom: 30}}>
            <a
              href={androidLink}
              onClick={() => {
                if (refcode) {
                  copyToClipBoard(refcode);
                  message.success(`${refcode} ${t('dialog.refCode')}`, 6);
                  return;
                }
              }}>
              <span className="install-btn ios-btn">{t('button.getApp')}</span>
            </a>
          </div>
        )}

        <div className="step-container">
          <div>{t('label.instruction')}</div>
          {isDesktop ? (
            <>
              <small>{t('tips.mobile.tips1')}</small>
              <small>{t('tips.mobile.tips2')}</small>
              <small>{t('tips.mobile.tips3')}</small>
            </>
          ) : (
            <>
              <small>{t('tips.desktop.tips1')}</small>
              <small>{t('tips.desktop.tips2')}</small>
              <small>{t('tips.desktop.tips3')}</small>
              <small>{t('tips.desktop.tips4')}</small>
            </>
          )}
        </div>
        {clientInfo.announcement ? (
          <>
            <div className="announcement-label-txt">
              {t('announcement.label')}
            </div>
            <div className="announcement-container">
              <div className="icon-container">
                <img
                  src={annoucement_icon}
                  alt={'annoucement_icon'}
                  className="annoucement-icon"
                />
              </div>
              <div className="announcement-style">
                <div className="announcement-title">
                  {t('announcement.title')}
                </div>
                <div
                  className="announcement-content"
                  dangerouslySetInnerHTML={{
                    __html: isiOS
                      ? t('announcement.mobile.content')
                      : t('announcement.content'),
                  }}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div className="lang-container">
        <div className="lang-style">
          <button className="lang-txt" onClick={() => changeLanguage('en')}>
            EN
          </button>
          <div className="lang-txt">|</div>
          <button className="lang-txt" onClick={() => changeLanguage('th')}>
            TH
          </button>
        </div>
        <div className="copy-right">{`© 2021 ${clientInfo.brandName} Copyright Reserved`}</div>
      </div>
    </div>
  );
}
